<template>
  <div class="modal ? 'okvir-modal' : 'okvir'">
    <v-overlay v-if="loading"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      style="z-index: 100">
      <div v-if="loading"
        class="text-center"
        style="
          position: absolute;
          z-index: 101;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        ">
        <v-progress-circular indeterminate
          size="86"
          color="primary">
        </v-progress-circular>
      </div>
    </v-overlay>
    <div class="resizable"
      style="text-align: center; border-radius: 25px !important">
      <v-card tile
        color="white"
        class="pb-3"
        style="border-radius: 25px !important">
        <v-card-title class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">{{ title }}</span>
        </v-card-title>
        <v-form ref="primkaForm"
          @submit.prevent="submit">
          <div style="
              display: flex;
              margin-top: 15px;
              margin-left: 5px;
              margin-right: 5px;
            ">
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              disabled
              label="Prodajno mjesto"
              v-model="selectedLocation"
              :items="locations"
              item-text="name"
              item-value="value"
              auto
              return-object
              @change="getWarehouses()"
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              disabled
              label="Skladište"
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              dense
              outlined
              :disabled="tipDokumenta == 'knjizno_odobrenje'"
              label="Dobavljač"
              v-model="selectedDobavljac"
              :items="dobavljaci"
              item-text="name"
              item-value="value"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              dense
              outlined
              :disabled="tipDokumenta == 'knjizno_odobrenje'"
              label="Način plaćanja"
              v-model="selectedNAcinPlacanja"
              :items="nacinplacanja"
              item-text="name"
              item-value="value"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <!-- <label class="datepickerLabel">Datum</label> -->
            <date-picker ref="datepicker"
              :clearable="false"
              prefix-class="xmx"
              class="datetimepicker"
              v-model="invoiceDate"
              placeholder="Datum"
              type="date"
              valueType="timestamp"
              format="DD.MM.YYYY"
              :default-value="new Date()"
              :popup-style="{ zIndex: 9999 }"
              :disabled-date="disableFutureDates"
              :rules="[rules.req]"
              :append-to-body="true">
            </date-picker>
            <v-text-field v-if="tipDokumenta == 'primka'"
              class="pl-2"
              hide-details
              outlined
              :label="tipDokumenta == 'primka'
                ? 'Broj primke'
                : 'Broj knjižnog odobrenja'
                "
              dense
              v-model="brojPrimke"
              :rules="[rules.req]"></v-text-field>

            <v-text-field v-if="tipDokumenta == 'knjizno_odobrenje'"
              class="pl-2"
              hide-details
              outlined
              label="Broj knjižnog odobrenja"
              dense
              v-model="brojKnjiznog"
              :rules="[rules.req]"></v-text-field>
            <v-autocomplete class="pl-2"
              hide-details
              outlined
              dense
              v-model="pdvIncluded"
              :items="pdvIncludedOptions"
              item-text="name"
              item-value="value" />
          </div>
        </v-form>
        <!-- <v-row>
        <v-col style="align-items: left; text-align: left">
          <v-btn id="success" class="btn infoButton mt-2 mb-2" @click="addRow"
            >Dodaj artikl</v-btn
          >
        </v-col>
      </v-row> -->
        <v-row>
          <v-col class="pb-0 mb-0"
            cols="8">
            <div style
              class="table-sm"
              ref="table"></div>
          </v-col>
          <v-col cols="4"
            class="pb-0 mb-0">

            <h4 class="mt-2"
              style="color:white; background-color: #3EA2E6; font-weight: 700;">Povratna naknada</h4>
            <v-divider></v-divider>
            <div class="table-sm pt-0 mt-0"
              ref="refund"></div>

            <h4 class="mt-5"
              style="background-color: #dc322f; color: white; font-weight: 700;">Ukupni porez za račun</h4>
            <v-divider></v-divider>
            <div class="table-sm pt-0 mt-0"
              ref="taxTable"></div>

            <h4 class="mt-5"
              style="background-color: #B7E1CD; color: black; font-weight: 700;">Ukupni iznos računa</h4>
            <v-divider></v-divider>
            <div class="table-sm pt-0 mt-0"
              ref="totalTable"></div>
          </v-col>

        </v-row>

        <v-card-actions class="pa-0 ma-0 mr-5">
          <v-row>
            <v-col style="align-items: right; text-align: right">
              <v-menu top
                origin="center center"
                offset-y
                style="background-color: red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn id="success"
                    class="btn warningButton mt-2 mb-2 mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @touchstart.stop="">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="custom-list">
                  <v-list-item v-if="!modal"
                    @click="showDoc"
                    @touchstart="showDoc"
                    class="btn warningButton custom-list-item mb-2">
                    <v-list-item-title>
                      <span>Pregled dokumenata</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="clearData"
                    @touchstart="clearData"
                    class="btn dangerButton custom-list-item">
                    <v-list-item-title>
                      <span>Obriši sve</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn id="success"
                v-if="modal"
                class="btn dangerButton mt-2 mb-2 mr-2"
                @click="closeModal()"
                @touchstart="closeModal()">Odustani</v-btn>
              <v-btn id="success"
                class="btn successButton mt-2 mb-2"
                @click="presubmit"
                @touchstart="presubmit">Spremi</v-btn>
            </v-col></v-row>
        </v-card-actions>
      </v-card>
    </div>

    <fab-menu v-if="!$vuetify.breakpoint.smAndDown"></fab-menu>
  </div>
</template>
<script>
import { TabulatorFull as Tabulator, CalcComponent } from 'tabulator-tables'
import { df, functions } from '@/plugins/firebase'
import state from '@/state'
import { Primka } from '@/modules/point-of-sale/components/warehouse/warehouse-module/models/Primka.js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'
import FabMenu from '@/modules/company/components/FabMenu'
import { clone } from '@/plugins/utils'

export default {
  components: {
    DatePicker,
    FabMenu
  },
  modules: {},
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  props: {
    modal: Boolean,
    tipDokumenta: String,
    akcija: String,
    primka: {
      type: Object,
      required: true // Ovdje kažeš da je obavezno proslijediti ovaj prop
    }
  },
  computed: {
    title () {
      var title
      switch (this.tipDokumenta) {
        case 'primka':
          title = 'Editiranje primke - ručni unos'
          break
        case 'knjizno_odobrenje':
          switch (this.akcija) {
            case 'add':
              title = 'Knjižno odobrenje za primku ' + this.brojPrimke
              break
            case 'update':
              title = 'Editiranje knjižnog odobrenja'
              break
          }
      }

      return title
    }
  },
  data: function () {
    return {
      checkboxes: {},
      absolute: true,
      opacity: 0.46,
      overlay: true,
      loading: false,
      tableHeight: window.innerHeight - 185 + 'px',
      tableWidth: window.innerWidth - 200 + 'px',
      margin: '56px',
      rules: {
        req: rules.req()
      },
      tabulator: null, // variable to hold your table
      locations: [],
      selectedLocation: undefined,
      warehouses: [],
      selectedWarehouse: undefined,
      invoiceDate: Date.now(),
      brojPrimke: undefined,
      brojKnjiznog: undefined,
      idPrimke: undefined,
      dobavljaci: [],
      selectedDobavljac: undefined,
      selectedNAcinPlacanja: undefined,
      nacinplacanja: [
        { name: 'Virman', value: 'Virman' },
        { name: 'Gotovina', value: 'Gotovina' },
        { name: 'Ostalo', value: 'Ostalo' }
      ],
      tableData: [],
      taxData: [
        { percentage: '0%', base: '', amount: '' },
        { percentage: '5%', base: '', amount: '' },
        { percentage: '13%', base: '', amount: '' },
        { percentage: '25%', base: '', amount: '' },
        { percentage: '', base: 'Ukupno:', amount: '' }
      ],
      artikli: [],
      primkaZaEdit: undefined,
      pdvIncluded: false,
      pdvIncludedOptions: [
        { name: 'Cijena sa pdv-om', value: true },
        { name: 'Cijena bez pdv-a', value: false }
      ],
      tabulatorTax: null,
      tabulatorRefund: null,
      refundData: [],
      totalData: []
    }
  },
  methods: {
    disableFutureDates (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > today
    },
    initTotal () {
      this.tabulatorTotal = new Tabulator(this.$refs.totalTable, {
        layout: 'fitColumns',
        headerVisible: false,
        rowHeight: 29,
        columns: [
          {
            title: 'Ukupni iznos računa',
            field: 'total',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            },
            editor: 'input',
            editorParams: { selectContents: true, verticalNavigation: 'editor' },
            validator: [{
              type: this.numberValidation,
              parameters: {
                allowEmpty: false
              }
            }],
            formatter: 'numberFormat',
            headerSort: false
          }
        ],
        data: [
          { total: '' }
        ]
      })

      this.tabulatorTotal.on('cellTap', function (e, cell) {
        cell.edit()
      })
    },

    initRefund () {
      this.tabulatorRefund = new Tabulator(this.$refs.refund, {
        layout: 'fitColumns',
        rowHeight: 29,
        validationMode: 'highlight',
        columns: [
          {
            title: 'Količina',
            field: 'quantity',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return ''
            },
            cellEdited: function (cell) {
              if (!cell.isValid()) {
                cell.setValue('')
              }
            },
            editorEmptyValue: '',
            editor: 'input',
            editorParams: { selectContents: true, verticalNavigation: 'editor' },
            validator: [{
              type: this.numberValidation,
              parameters: {
                allowEmpty: true
              }
            }],
            formatter: 'numberFormat',
            headerSort: false
          },
          {
            title: 'Ukupna cijena',
            field: 'total',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return ''
            },
            cellEdited: function (cell) {
              if (!cell.isValid()) {
                cell.setValue('')
              }
            },
            editor: 'input',
            editorParams: { selectContents: true, verticalNavigation: 'editor' },
            editorEmptyValue: '',
            validator: [{
              type: this.numberValidation,
              parameters: {
                allowEmpty: true
              }
            }],
            formatter: 'numberFormat',
            headerSort: false
          }
        ],
        data: [
          { quantity: '', total: '' }
        ]
      })

      this.tabulatorRefund.on('cellTap', function (e, cell) {
        cell.edit()
      })

      this.tabulatorRefund.on('cellTouchEnd', function (e, cell) {
        this.handleInvalidCell(cell)
      }.bind(this))

      this.tabulatorRefund.on('cellEdited', function (cell) {
        this.handleInvalidCell(cell)
      }.bind(this))
    },
    handleInvalidCell (cell) {
      var fieldName = cell.getField()
      if (fieldName === 'artikl' || fieldName === 'mjernaJedinica') {
        return
      }

      if (!this.numberValidation(cell, cell.getValue())) {
        cell.setValue('')
        cell.getElement().blur()
        setTimeout(() => {
          this.tabulatorTax.redraw(true)
        }, 10)
      }
    },
    initTaxTable () {
      this.tabulatorTax = new Tabulator(this.$refs.taxTable, {
        layout: 'fitColumns',
        rowHeight: 29,
        columns: [
          { title: 'Postotak', field: 'percentage', width: 100, hozAlign: 'center', headerSort: false },
          {
            title: 'Osnovica',
            field: 'base',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              }
              return ''
            },
            cellEdited: function (cell) {
              if (!cell.isValid()) {
                cell.setValue('')
              }
            },
            editor: 'input',
            editorParams: { selectContents: true, verticalNavigation: 'editor' },
            editorEmptyValue: '',
            validator: [{
              type: this.numberValidation,
              parameters: {
                allowEmpty: true
              }
            }],
            formatter: 'numberFormat',
            headerSort: false
          },
          {
            title: 'Iznos',
            field: 'amount',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              }
              return ''
            },
            cellEdited: function (cell) {
              if (!cell.isValid()) {
                cell.setValue('')
              }
            },
            editor: 'input',
            editorParams: { selectContents: true, verticalNavigation: 'editor' },
            editorEmptyValue: '',
            validator: [{
              type: this.numberValidation,
              parameters: {
                allowZero: true,
                allowEmpty: true
              }
            }],
            formatter: 'numberFormat',
            headerSort: false
          }
        ],
        validationMode: 'highlight',
        data: [
          { percentage: '0%', base: '', amount: '' },
          { percentage: '5%', base: '', amount: '' },
          { percentage: '13%', base: '', amount: '' },
          { percentage: '25%', base: '', amount: '' }
        ]
      })

      this.tabulatorTax.on('cellTap', function (e, cell) {
        cell.edit()
      })

      this.tabulatorTax.on('cellTouchEnd', function (e, cell) {
        this.handleInvalidCell(cell)
      }.bind(this))

      this.tabulatorTax.on('cellEdited', function (cell) {
        this.handleInvalidCell(cell)
      }.bind(this))
    },
    validateBeforeSubmit () {
      const table = this.tabulatorTax
      const data = table.getData()

      const isValid = data.some(row =>
        row.percentage !== 'Ukupno' && // Ignoriraj red s "Ukupno"
        row.base !== '' &&
        row.amount !== ''
      )

      if (!isValid) {
        this.showMsgBox({
          title: 'Upozorenje',
          text: 'Morate popuniti barem jedan red poreza prije nego što nastavite!',
          actions: ['ok'],
          color: 'error'
        })
        return false
      }

      var totalRow = this.tabulatorTotal.getData()
      if (totalRow[0].total === '') {
        this.showMsgBox({
          title: 'Upozorenje',
          text: 'Morate popuniti ukupni iznos računa prije nego što nastavite!',
          actions: ['ok'],
          color: 'error'
        })
        return false
      }

      return true // Dozvoli nastavak ako je validacija prošla
    },
    recalculate () {
      if (!this.tabulator) {
        return
      }

      this.tabulator.redraw(true)
    },
    isDisableRabatChange () {
      if (!this.tabulator) {
        return false
      }

      if (this.tabulator.getData().length > 0) {
        return true
      }
    },

    handleCheckboxChange (columnId, checked) {
      const otherColumnId =
        columnId === 'jedinicnaCijenaSPdv'
          ? 'jedinicnaCijenaBezPdv'
          : 'jedinicnaCijenaSPdv'
      // const otherCheckbox = this.checkboxes[otherColumnId]
      if (checked) {
        // otherCheckbox.checked = false
        // otherCheckbox.indeterminate = false

        // Enable editing for the current column and disable for the other
        this.tabulator.getColumn(columnId).updateDefinition({ editor: true })
        this.tabulator
          .getColumn(otherColumnId)
          .updateDefinition({ editor: false })

        // Update selectedColumn variable
        this.selectedColumn = columnId
      } else {
        // this.checkboxes[columnId].indeterminate = true

        // Reset editable status
        this.tabulator.getColumn(columnId).updateDefinition({ editor: false })
        this.tabulator
          .getColumn(otherColumnId)
          .updateDefinition({ editor: true })
      }

      // Update the checkbox states
      this.updateCheckboxStates(columnId, checked)

      this.tabulator.getColumn('rabat').updateDefinition({ editor: true })
      this.tabulator
        .getColumn('jedinicnaCijenaSRabatom')
        .updateDefinition({ editor: true })
    },

    closeModal () {
      this.$emit('closeModal')
    },
    calculateHeights () {
      this.tabulator.setHeight(window.innerHeight - 185)
    },
    presubmit () {
      if (!this.$refs.primkaForm.validate()) return
      if (this.tabulator.validate() !== true) return

      if (this.tabulator.getData().length === 0) {
        this.showMsgBox({
          title: 'Upozorenje',
          text: 'Morate dodati barem jedan artikl!',
          actions: ['ok'],
          color: 'error'
        })
        return
      }

      this.confirm({
        title: 'Primka',
        message: 'Jeste li sigurni da želite spremiti trenutne podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Spremi',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.submit()
        }
      })
    },
    submit () {
      if (!this.validateBeforeSubmit()) {
        return
      }

      this.loading = true

      const primka = new Primka()

      this.tabulator.getData().forEach((element) => {
        primka.setManualArtikl(element, this.pdvIncluded)
      })

      var povratnaNaknada = this.tabulatorRefund.getData()
      var kolicinaPn = 0
      var ukupnoCijenaPn = 0
      povratnaNaknada.forEach(pv => {
        kolicinaPn += parseFloat(pv.quantity.toString().replace(/,/g, '.'))
        ukupnoCijenaPn += parseFloat(pv.total.toString().replace(/,/g, '.'))
      })

      if (kolicinaPn >= 0 && ukupnoCijenaPn >= 0) {
        primka.setManualArtikl({
          id: '-1',
          artikl: 'Povratna naknada',
          mjernaJedinica: 'komad',
          kolicina: kolicinaPn,
          ukupnaCijena: ukupnoCijenaPn,
          item: {
            id: '-1',
            name: 'Povratna naknada',
            selling_unit: 'kom',
            storage_unit: 'kom'
          }
        }, this.pdvIncluded)
      }

      primka.setUkupnoPovratnaNaknada()

      primka.setIdFirme(state.getCurrentCompany().id)
      primka.setIdSkladista(this.selectedWarehouse.id)
      primka.setNazivSkladista(this.selectedWarehouse.name)
      primka.setDatum(this.invoiceDate)
      primka.setBrojPrimke(this.brojPrimke)
      primka.setDobavljac(this.selectedDobavljac.name)
      primka.setNacinPlacanja(this.selectedNAcinPlacanja.value)

      // if (this.pdvIncluded) {
      //   primka.setUkupanIznosSPdv(ukupno)
      //   primka.setUkupanIznosBezPdv('0')
      // } else {
      //   primka.setUkupanIznosBezPdv(ukupno)
      //   primka.setUkupanIznosSPdv('0')
      // }

      primka.setIdLokacije(this.selectedWarehouse.location_id)
      primka.setProdajnoSkladiste(this.selectedWarehouse.selling_warehouse)
      primka.setAkcija(this.akcija)
      primka.setTipDokumenta('primka_rucni_upis')

      var porezi = this.tabulatorTax.getData()

      var ukupnoBase = 0
      var ukupno = 0
      var ukupnoPdv = 0

      porezi.forEach((porez, index) => {
        porezi[index].percentage = porezi[index].percentage.replace('%', '').replace(':', '').trim()

        var bString = porez.base.toString().replace(/,/g, '.')
        var aString = porez.amount.toString().replace(/,/g, '.')

        if (bString === '') {
          bString = '0'
        }

        if (aString === '') {
          aString = '0'
        }

        var base = parseFloat(bString)
        var amount = parseFloat(aString)

        if (porez.percentage !== 'Ukupno:') {
          ukupnoBase += base
          ukupnoPdv += amount
        }
      })

      var tt = this.tabulatorTotal.getData()
      var total = parseFloat(tt[0].total.toString().replace(/,/g, '.'))
      ukupno = total

      primka.setUkupanIznosPdv(ukupnoPdv)
      primka.setUkupanIznosBezPdv(ukupnoBase)
      primka.setUkupanIznosSPdv(ukupno)

      const noviPorezi = porezi.map(porez => {
        var base = porez.base ? porez.base.toString().replace(/,/g, '.') : '0'
        var amount = porez.amount ? porez.amount.toString().replace(/,/g, '.') : '0'

        return {
          percentage: porez.percentage,
          base: Math.round(parseFloat(base) * 100),
          amount: Math.round(parseFloat(amount) * 100)
        }
      })

      primka.setPorezi(noviPorezi)

      var newdeliveryNote = functions.httpsCallable('newdeliverynote')

      var resourceID = this.primka.id

      if (this.tipDokumenta === 'primka') {
        delete primka.povezanaPrimka
      }

      newdeliveryNote({
        action: {
          operation: 'set',
          entity: 'nova_primka',
          resource_id: resourceID,
          params: primka
        }
      }).then((result) => {
        if (result.data.code === 200) {
          this.loading = false
          this.showMsgBox({
            text: 'Uspješno spremljena primka.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.createTable()
          this.closeModal()
        } else {
          this.loading = false
          this.showMsgBox({
            text: 'Nije uspjelo spremanje primke.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        }
      })
    },
    showDoc () {
      this.$modal.show('primke')
    },
    clearData () {
      this.confirm({
        title: 'Brisanje podataka',
        message: 'Jeste li sigurni da želite obrisati upisane podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Obriši',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.tabulator.setData([])
        } else {
        }
      })
    },

    recursiveInvert (obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'number') {
            obj[key] = obj[key] * -1
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            this.recursiveInvert(obj[key])
          }
        }
      }
    },

    addRow () {
      if (this.tabulator.validate() !== true) return
      this.tabulator.addRow({}).then(function (row) {
        // Dobijte ćeliju prve kolone novog retka
        var cell = row.getCell('artikl')
        if (cell) {
          // Start editing the cell
          cell.edit()
        }
      })
    },
    zbroji (a, b) {
      // Pretvaranje a i b u brojeve ako su stringovi
      a = typeof a === 'string' ? a.replace(',', '.') : a
      b = typeof b === 'string' ? b.replace(',', '.') : b

      // Pretvaranje a i b u brojeve
      a = parseFloat(a) || 0
      b = parseFloat(b) || 0

      // Zbrajanje i formatiranje rezultata
      return (a + b).toFixed(2).replace('.', ',')
    },
    pomnozi (a, b) {
      // Pretvaranje a i b u brojeve ako su stringovi
      a = typeof a === 'string' ? a.replace(',', '.') : a
      b = typeof b === 'string' ? b.replace(',', '.') : b

      // Pretvaranje a i b u brojeve
      a = parseFloat(a) || 0
      b = parseFloat(b) || 0

      // Množenje i formatiranje rezultata
      const rezultat = (a * b).toFixed(2)

      // Vraćanje rezultata kao string sa zarezom umjesto točke
      return rezultat.replace('.', ',')
    },

    numberValidation (cell, num, parameters) {
      let allowZero = false
      if (parameters?.allowZero ?? false) {
        allowZero = true
      }
      if (!num || num === '' || (num === 0 && !allowZero)) return false
      var bla = num.toString().trim()
      if (bla === '') return false
      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      if (allowZero && parseInt(Math.round(number * 100)) >= 0) return true
      if (parseInt(Math.round(number * 100)) > 0) return true
      return false
    },
    async getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('id', '==', this.selectedWarehouse.location_id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
            })
            if (this.locations.length === 1) {
              this.selectedLocation = this.locations[0]
            }
            this.locations = this.locations.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
            this.$forceUpdate()
          }
        })
    },
    async getDobavljaci (customerName) {
      this.showLocationSelector = false
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((response) => {
          Object.keys(response.data().associates).forEach((id) => {
            var dob = {
              name: response.data().associates[id].name,
              value: id,
              all: response.data().associates[id]
            }
            if (dob.name === customerName) {
              this.selectedDobavljac = dob
            }
            this.dobavljaci.push(dob)
          })
          // this.dobavljaci = response.data().associates
        })
    },
    async getWarehouses (whID) { },
    async getItems () {
      const query = df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')
        .where('type', '==', 'GOODS')

      const documentSnapshots = await query.get()
      if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
        documentSnapshots.docs.forEach((it) => {
          const item = it.data()

          if (!item.has_norm) {
            if (item.warehouse_units?.units) {
              const map = item.warehouse_units.units
              const entries = Object.entries(map)
              entries.sort(([keyA, valueA], [keyB, valueB]) => {
                return (
                  (valueB.default_store === true) -
                  (valueA.default_store === true)
                )
              })
              const sortedMap = Object.fromEntries(entries)
              var unit = []
              for (const key in sortedMap) {
                if (map[key].storage_unit !== '') {
                  unit.push({ label: map[key].storage_unit, value: map[key].id })
                }
              }
              this.artikli.push({
                id: item.id,
                label: item.name,
                value: item.name,
                mjerneJedinice: unit,
                stopaPdv: item.tax_group_object?.pdv_rate ?? '',
                item: item
              })
            }
          }
        })
        // MAKNUTO JER IMAMO POSEBNU TABLICU ZA POVRATNU NAKNADU
        // var povratnaUnitId = uuidv4()
        // this.artikli.push({
        //   id: '-1',
        //   label: 'Povratna naknada',
        //   value: 'Povratna naknada',
        //   mjerneJedinice: [{ label: 'kom', value: povratnaUnitId }],
        //   stopaPdv: '0',
        //   // cijena: '0.07',
        //   item: {
        //     id: '-1',
        //     name: 'Povratna naknada',
        //     selling_unit: 'kom',
        //     storage_unit: 'kom',
        //     warehouse_units: {
        //       base_unit: povratnaUnitId,
        //       default_sale: povratnaUnitId,
        //       default_store: povratnaUnitId,
        //       units: {
        //         [povratnaUnitId]: {
        //           base_unit: true,
        //           default_sale: true,
        //           default_store: true,
        //           id: povratnaUnitId,
        //           is_selling_item: true,
        //           storage_amount: 1000,
        //           storage_unit: 'kom'
        //         }
        //       }
        //     }
        //   }
        // })
      }
      this.artikli = this.artikli.sort((a, b) =>
        a.label.localeCompare(b.label)
      )
    },
    createTable () {
      var that = this

      var rowContextMenu = [
        {
          label:
            "<span class='mdi mdi-content-copy iclass btn infoButton'> Kopiraj artikl</span>",
          action: function (e, row) {
            var rowData = row.getData()
            const copiedData = JSON.parse(JSON.stringify(rowData))
            that.tabulator.addRow(copiedData)
          }
        },
        {
          label:
            "<span class='mdi mdi-delete-forever iclass btn dangerButton'> Obriši artikl</span>",
          action: function (e, row) {
            row.delete()
          }
        },
        {
          separator: true
        }
        // {
        //   label: 'Admin',
        //   menu: [
        //     {
        //       label: '<i ></i> Obriši redak',
        //       action: function (e, row) {
        //         row.delete()
        //       }
        //     },
        //     {
        //       label: '<i ></i> Disabled Option',
        //       disabled: true
        //     }
        //   ]
        // }
      ]
      this.tabulator = new Tabulator(this.$refs.table, {
        persistence: false,
        layout: 'fitColumns',
        height: this.tableHeight,
        rowContextMenu: rowContextMenu,
        validationMode: 'highlight',
        rowheader: true,
        keybindings: {
          addNewRow: 'ctrl + n'
        },
        data: this.tableData, // link data to table
        reactiveData: true, // enable data reactivity
        columns: [
          {
            visible: false,
            width: 40,
            field: 'id'
          },
          {
            width: 40,
            formatter: 'rownum',
            hozAlign: 'center',
            headerSort: false,
            widthGrow: 3,
            titleFormatter: function (cell, formatterParams, onRendered) {
              const el = document.createElement('button')
              el.innerHTML = '+'
              el.classList.add('butt')
              el.classList.add('infoButton')
              el.addEventListener('click', function () {
                that.addRow()
              })

              el.addEventListener('touchstart', function (event) {
                event.preventDefault() // Sprječava dvostruki klik na mobilnim uređajima
                that.addRow()
              }, { passive: true })

              return el
            }
          },
          {
            title: 'Artikl',
            field: 'artikl',
            validator: 'required',
            editor: 'list',
            widthGrow: 3,
            editorParams: {
              values: this.artikli,
              autocomplete: 'true',
              allowEmpty: false,
              listOnEmpty: true
            },
            headerSort: false,
            cellEdited: function (cell) {
              var row = cell.getRow()
              var artiklValue = cell.getValue()

              var selectedArtikl = clone(
                this.artikli.find(
                  (item) => item.value === artiklValue && !item.id.includes('*')
                )
              )

              if (selectedArtikl) {
                row.update({ mjernaJedinica: '' }) // Resetiraj polje mjernaJedinica
                row.update({ mjernaJedinicaId: '' }) // Resetiraj polje mjernaJedinica

                if (selectedArtikl.mjerneJedinice[0] && selectedArtikl.mjerneJedinice[0].value !== '') {
                  row.update({
                    mjernaJedinica: selectedArtikl.mjerneJedinice[0].value
                  })
                  row.update({
                    mjernaJedinicaId: selectedArtikl.mjerneJedinice[0].value
                  })
                }
                row.update({ stopaPdv: '' })
                row.update({ stopaPdv: selectedArtikl.stopaPdv + '' })
                row.update({ id: selectedArtikl.id })
                row.update({ jedinicnaCijenaSPdv: selectedArtikl.cijena })
                row.update({ item: selectedArtikl.item })
                this.tabulator.redraw()
                this.tabulator.navigateRight()
              }
            }.bind(this)
          },
          {
            title: 'Mj. jedinica',
            field: 'mjernaJedinica',
            validator: 'required',
            editor: 'list',
            widthGrow: 3,
            editorParams: function (cell) {
              var row = cell.getRow()
              var artiklNaziv = row.getData().artikl
              var selectedArtikl = this.artikli.find(
                (artikl) => artikl.value === artiklNaziv
              )
              if (selectedArtikl) {
                return { values: selectedArtikl.mjerneJedinice }
              } else {
                return { values: [] }
              }
            }.bind(this),
            formatter: function (cell, formatterParams, onRendered) {
              var value = cell.getValue()
              if (!value) {
                return
              }
              var row = cell.getRow()
              var artiklNaziv = row.getData().artikl.trim()

              var selectedArtikl = that.artikli.find(
                (artikl) => artikl.value.trim() === artiklNaziv
              )

              var option = selectedArtikl.mjerneJedinice.find(
                (opt) => opt.value === value
              )
              return option ? option.label : value
            },
            headerSort: false
          },
          {
            title: 'Količina',
            field: 'kolicina',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return ''
            },
            widthGrow: 3,
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Količina: ' + cell.getValue()
              }
            },
            cellEdited: function (cell) {
              if (!cell.isValid()) {
                cell.setValue('')
              }
            },
            editorEmptyValue: '',
            editor: 'input',
            editorParams: { selectContents: true },
            validator: [{ type: this.numberValidation }, 'required'],
            formatter: 'numberFormat',
            // bottomCalcFormatter: 'numberFormat',
            // bottomCalc: 'sum',
            // bottomCalcParams: { precision: 2 },
            headerSort: false
          },
          {
            title:
              'Cijena',
            headerTooltip:
              '<b>Nabavna cijena</b>',
            field: 'jedinicnaCijena',
            headerFilterParams: {
              columnId: 'jedinicnaCijena'
            },
            widthGrow: 3,
            hozAlign: 'right',
            editor: true,
            editorParams: { selectContents: true },
            formatter: 'numberFormat',
            headerSort: false,
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            },
            cellEdited: function (cell) {
              if (!cell.isValid()) {
                cell.setValue('')
              }
            },
            editorEmptyValue: ''
          },
          {
            title:
              'Ukupno',
            field: 'ukupnaCijena',
            hozAlign: 'right',
            visible: false,
            widthGrow: 3,
            editor: true,
            formatter: 'numberFormat',
            // bottomCalc: 'sum',
            // bottomCalcParams: { precision: 2 },
            // , mutator: this.ukupnaCijenaSPdv
            headerSort: false,
            // bottomCalcFormatter: 'numberFormat',
            editorParams: { selectContents: true },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return (
                  'Ukupno<span style="color:#1fd655; font-size:10px"> S PDV</span>: ' +
                  cell.getValue()
                )
              }
            },
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          }
          // ,
          // {
          //   formatter: 'buttonCross',
          //   hozAlign: 'center',
          //   cellClick: function (e, cell) {
          //     cell.getRow().delete()
          //   // TRIGGER POST REQUEST
          //   },
          //   headerSort: false
          // }
        ]
      })

      this.tabulator.on('cellTap', function (e, cell) {
        cell.edit()
      })

      this.tabulator.on('cellTouchEnd', function (e, cell) {
        this.handleInvalidCell(cell)
      }.bind(this))

      this.tabulator.on('cellEdited', function (cell) {
        this.handleInvalidCell(cell)
      }.bind(this))

      Tabulator.extendModule('keybindings', 'actions', {
        addNewRow: function () {
          // delete selected rows
          that.tabulator.addRow({}).then(function (row) {
            var cell = row.getCell('artikl')
            if (cell) {
              cell.edit()
            }
          })
        }
      })

      Tabulator.extendModule('format', 'formatters', {
        numberFormat: function (cell, formatterParams) {
          var result
          const num = cell.getValue()
          if (num) {
            const input = cell.getValue().toString().replace(',', '.')

            // Parse the input as a floating point number
            const num = parseFloat(input)

            // Check for invalid input
            if (isNaN(num)) {
              return
              // throw new Error('Invalid input')
            }

            // Handle cases where there are up to 3 decimal places
            if (input.includes('.')) {
              const decimalPart = input.split('.')[1]
              if (decimalPart.length <= 3) {
                result = num.toFixed(2).replace('.', ',')
              } else {
                result = num.toFixed(3).replace('.', ',')
              }
            } else {
              // No decimal point, so just add ",00"
              if (formatterParams.percentage) {
                return num.toFixed(2).replace('.', ',') + '%'
              }
              result = num.toFixed(2).replace('.', ',')
            }
          }

          switch (this.tipDokumenta) {
            case 'knjizno_odobrenje':
              return '-' + result
            case 'primka':
              return result
            default:
              return result
          }
        }.bind(this)
      })
    }
  },
  created () {
    window.addEventListener('resize', this.calculateHeights)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
    window.removeEventListener('resize', this.calculateHeights)
  },
  async mounted () {
    this.margin = state.getMargin()
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })
    df.doc(`warehouses/${this.primka.warehouse_id}`)
      .get()
      .then((response) => {
        this.selectedWarehouse = response.data()
        this.warehouses = [this.selectedWarehouse]
        this.getLocations(this.selectedWarehouse.location_id)
      })

    await this.getItems()
    this.getDobavljaci(this.primka.customer_name)

    this.idPrimke = this.primka.id

    this.selectedNAcinPlacanja = {
      name: this.primka.fob_description,
      value: this.primka.fob_description
    }
    this.invoiceDate = this.primka.created * 1000
    this.brojPrimke = this.primka.delivery_number

    if (this.primka.rabat_calc_type < 0) {
      this.primka.rabat_calc_type *= -1
    }

    if (this.primka.porez_calc_type < 0) {
      this.primka.porez_calc_type *= -1
    }

    this.rabatCalcType = this.primka.rabat_calc_type ?? 1
    this.porezCalcType = this.primka.porez_calc_type ?? 1

    this.createTable()
    this.initTaxTable()
    this.initRefund()
    this.initTotal()

    var tableData = []
    if (Object.keys(this.primka.extended_items).length > 0) {
      Object.keys(this.primka.extended_items).forEach((id) => {
        var it = this.primka.extended_items[id]

        if (it.id === '-1') {
          var quantityPn = it.warehouse_data.quantity / 1000
          var totalPn = it.warehouse_data.value ? it.warehouse_data.value / 10000 : it.warehouse_data.wholesale_price / 100

          this.refundData = [
            {
              quantity: quantityPn.toString().replace('.', ','),
              total: totalPn.toString().replace('.', ',')
            }
          ]

          return
        }

        if (
          this.tipDokumenta === 'knjizno_odobrenje' &&
          this.akcija === 'update'
        ) {
          this.recursiveInvert(it)
          this.brojKnjiznog = this.primka.delivery_number
        }

        var itID = it.id
        if (itID.includes('*')) {
          itID = itID.split('*')[1]
        }

        var unitID = ''
        var item

        this.totalData = [{ total: (this.primka.total_brutto / 100).toString().replace('.', ',') }]

        this.artikli.forEach((lagerIt) => {
          if (lagerIt.id === itID && lagerIt.mjerneJedinice.length > 0) {
            item = lagerIt.item
            lagerIt.mjerneJedinice.forEach((mj) => {
              if (mj.label === it.warehouse_data.received_amount_measure_unit) {
                unitID = mj.value
              }
            })
          }
        })

        if (it.item.name === 'Povratna naknada') {
          unitID = 'kom'
        }

        var quantity = (it.warehouse_data.quantity / 1000).toString()
        if (unitID !== '') {
          quantity = (
            it.warehouse_data.received_amount_quantity / 1000
          ).toString()
        }

        var ukupnaCijena = 0
        if (it.warehouse_data.wholesale_price > 0) {
          this.pdvIncluded = true
          ukupnaCijena = (it.warehouse_data.wholesale_price / 100).toString()
        }

        if (it.warehouse_data.value > 0) {
          this.pdvIncluded = false

          ukupnaCijena = (it.warehouse_data.value / 10000).toString()
        }

        var cijena = 0
        if (it.warehouse_data.sale_price > 0) {
          cijena = (it.warehouse_data.sale_price / 100).toString()
        }

        if (it.warehouse_data.received_amount_purchase_price > 0) {
          cijena = (
            it.warehouse_data.received_amount_purchase_price / 100000
          ).toString()
        }

        tableData.push({
          artikl: it.item.name,
          id: it.id,
          iznosPdv: (it.warehouse_data.vat_amount / 100).toString(),
          jedinicnaCijenaBezPdv: (
            it.warehouse_data.received_amount_purchase_price / 100000
          ).toString(),
          jedinicnaCijenaSPdv: (it.warehouse_data.sale_price / 100).toString(),
          kolicina: quantity.toString(),
          mjernaJedinica: unitID,
          mjernaJedinicaId: unitID,
          rabat: (it.warehouse_data.supplier_rebate / 100).toString(),
          // data.jedinicnaCijenaBezPdv * (1 - data.rabat / 100)
          jedinicnaCijenaSRabatom: (
            (it.warehouse_data.received_amount_purchase_price / 100000) *
            (1 - it.warehouse_data.supplier_rebate / 100 / 100)
          ).toString(),
          stopaPdv: (it.warehouse_data.vat / 100).toString(),
          ukupnaCijenaBezPdv: (it.warehouse_data.value / 10000).toString(),
          ukupnaCijenaSPdv: (
            it.warehouse_data.wholesale_price / 100
          ).toString(),
          ukupnaCijena: ukupnaCijena,
          jedinicnaCijena: cijena,
          item: item
        })

        const taxOrder = ['0%', '5%', '13%', '25%']
        if (this.primka && this.primka.taxes) {
          const taxData = Object.entries(this.primka.taxes)
            .map(([key, value]) => {
              return {
                percentage: key + '%',
                base: (value.base / 100).toString().replace('.', ','),
                amount: (value.amount / 100).toString().replace('.', ',')
              }
            })
            .filter(item => item !== null && item.percentage !== 'Ukupno:%' &&
              item.percentage !== 'Ukupno' &&
              item.base !== 'Ukupno:' &&
              item.base !== 'Ukupno') // Uklanjamo null vrijednosti iz map funkcije
            .sort((a, b) => {
              const aIndex = taxOrder.indexOf(a.percentage.replace(':', '').replace('%', ''))
              const bIndex = taxOrder.indexOf(b.percentage.replace(':', '').replace('%', ''))
              return aIndex - bIndex
            })
          this.taxData = taxData
        }
      })
    }

    if (!this.refundData || this.refundData.length === 0) {
      this.refundData = [
        { quantity: '', total: '' }
      ]
    }

    this.tabulatorTotal.on(
      'tableBuilt',
      function () {
        this.tabulatorTotal.setData(this.totalData) // Postavi podatke kada je tablica izgrađena
      }.bind(this)
    )

    this.tabulator.on(
      'tableBuilt',
      function () {
        this.tabulator.setData(tableData) // Postavi podatke kada je tablica izgrađena
      }.bind(this)
    )

    this.tabulatorTax.on(
      'tableBuilt',
      function () {
        this.tabulatorTax.setData(this.taxData) // Postavi podatke kada je tablica izgrađena
      }.bind(this)
    )

    this.tabulatorRefund.on(
      'tableBuilt',
      function () {
        this.tabulatorRefund.setData(this.refundData) // Postavi podatke kada je tablica izgrađena
      }.bind(this)
    )

    this.selectedLocation = this.locations.filter(
      (loc) => loc.id === this.primka.location_id
    )
  }
}
</script>
<style>
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal;
  text-overflow: clip;
  text-align: center;
  font-size: small;
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-col {
  background-color: #f2f2f2 !important;
}

.tabulator .tabulator-row .tabulator-cell {
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-edit-select option {
  height: 30px;
  /* Postavite željenu visinu stavki u dropdownu */
}

/* CSS medijski upiti */
.resizable {
  width: 100%;
}

/* @media (min-width: 1200px) {
  .resizable {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .resizable {
    width: 95%;
  }
}

@media (max-width: 767px) {
  .resizable {
    width: 95%;
  }
} */

.tabulator-edit-list .tabulator-edit-list-item {
  padding: 4px;
  height: 50px;
  color: #333;
  outline: none;
  vertical-align: middle;
  font-size: 18px;
  padding: 16px 0 0 10px;
}

.butt {
  border-radius: 5px;
  border: 2px solid black;
  background-color: white !important;
  color: black;
  padding: 0px 5px 0px 5px;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
}

.iclass {
  font-size: 20px;
  /* Veličina fonta */
  height: 30px;
  /* Visina ikone */
  line-height: 30px;
  /* Postavljanje vertikalnog centriranja */
  display: inline-flex;
  /* Omogućava postavljanje dimenzija i centriranje */
  align-items: center;
  /* Vertikalno centriranje */
  justify-content: center;
  /* Horizontalno centriranje */
  transition: color 0.3s, background-color 0.3s;
  /* Glatka tranzicija za promenu boje */
  color: black;
  /* Inicijalna boja teksta */
  background-color: white;
  /* Inicijalna pozadinska boja */
  padding: 5px;
  /* Unutrašnja margina za bolji izgled */
  border-radius: 5px;
  /* Zaobljeni uglovi */
}

/* Promena boje teksta i pozadinske boje na hover */
.iclass:hover {
  color: white;
  /* Promena boje teksta na hover */
  background-color: red;
  /* Promena pozadinske boje na hover */
}
</style>

<style scoped>
.table-sm {
  margin-top: 10px;
}

.custom-list {
  padding: 0px !important;
}

.custom-list-item {
  padding-bottom: 2px !important;
  background-color: transparent;
}

.okvir-modal {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
  border-radius: 25px !important;
}

.okvir {
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
}
</style>
